<template>
  <div class="register-box">
    <login-head title="找回密码" />
    <div class="register-content">
      <div class="register-from">
        <div class="register-title">
          <span>找回密码</span>
        </div>
        <div class="register-from-box">
          <div>
            <div class="register-from-tab">
              <span
                :class="{ active: registerType == 'mobile' }"
                @click="registerType = 'mobile'"
                >手机号找回</span
              >
              <!--              <span-->
              <!--                :class="{ active: registerType == 'mail' }"-->
              <!--                @click="registerType = 'mail'"-->
              <!--                >邮箱找回</span-->
              <!--              >-->
            </div>
            <div class="forget-from-info">
              <template v-if="registerType == 'mobile'">
                <div>
                  <a-input
                    size="large"
                    v-model="registerMobile"
                    placeholder="请输入注册手机号"
                  />
                </div>
                <div>
                  <a-input
                    size="large"
                    v-model="registerMobileCode"
                    placeholder="请输入短信验证码"
                  >
                    <template #suffix>
                      <v-count-down
                        :disabled="!registerMobile"
                        :trigger="() => sendMobileCode()"
                      >
                        <template #default>获取验证码</template>
                        <template #loading="scope"
                          >{{ scope.seconds }}秒后重新发送</template
                        >
                      </v-count-down>
                    </template>
                  </a-input>
                </div>
                <a-button
                  size="large"
                  class="btn-red register-btn"
                  @click="newPassword"
                  >下一步</a-button
                >
              </template>
              <template v-else>
                <div>
                  <a-input
                    size="large"
                    v-model="registerMail"
                    placeholder="请输入注册邮箱"
                  />
                </div>
                <div class="num-code">
                  <a-input
                    size="large"
                    style="width: 355px"
                    v-model="emailVerification"
                    placeholder="请输入验证码"
                  />
                  <div @click="refreshCode">
                    <verification :identifyCode="identifyCode" />
                  </div>
                </div>
                <a-button
                  size="large"
                  class="btn-red register-btn"
                  @click="email"
                  >发送电子邮件</a-button
                >
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="register-footer-protocol">
<!--        <div>
          <span @click="$router.push('/protocol/serve')">鸿联云帐号用户协议</span>
          <span @click="$router.push('/protocol')"
            >关于鸿联云帐号与隐私的声明</span
          >
&lt;!&ndash;          <span>常见问题</span>&ndash;&gt;
        </div>-->
        <p>Copyright © 鸿联云, All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
import loginHead from "@/page/components/loginHead.vue";
import verification from "@/page/components/verification.vue";
import VCountDown from "@/components/VCountDown";
import { checkMobileByReset, sendMobile } from "@/api/common";
export default {
  components: {
    VCountDown,
    loginHead,
    verification,
  },
  data() {
    return {
      registerMobile: "",
      registerMobileCode: "",
      registerMail: "",
      registerType: "mobile",
      identifyCodes: "0123456789qwerasdfzxcv",
      identifyCode: "89qw",
      emailVerification: "",
    };
  },
  methods: {
    newPassword() {
      const { registerMobile: mobile, registerMobileCode: code } = this;
      if (!mobile) {
        this.$message.error("请输入手机号");
        return;
      }
      if (!code) {
        this.$message.error("请输入验证码");
        return;
      }
      checkMobileByReset({ mobile, code }).then((res) => {
        this.$router.push(`/newPassword?token=${res["reset-password-token"]}`);
      });
    },
    email() {
      this.$router.push("/emailCode");
    },
    // 验证码处理
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 刷新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    sendMobileCode() {
      const { registerMobile } = this;
      if (!registerMobile) {
        this.$message.error("请输入手机号");
        return;
      }
      sendMobile(registerMobile, "password").then(() => {
        this.$message.success("发送成功！");
      });
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
      console.log(this.identifyCode);
    },
  },
};
</script>

<style lang="scss" scoped>
.obtain-code {
  color: #EA0B06;
  cursor: pointer;
}
.register-box {
  height: 100%;
  background: #f7f8fa;
  .register-content {
    height: calc(100% - 64px);
    padding: 24px 0;
    .register-from {
      background: #fff;
      width: 960px;
      margin: 0 auto;
      border-radius: 4px;
      .register-title {
        padding: 0 24px;
        height: 56px;
        border-bottom: 1px solid #f0f0f0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > span {
          font-size: 18px;
        }
      }
      .register-from-box {
        min-height: 584px;
        padding: 56px 0;
        > div {
          width: 480px;
          margin: 0 auto;
          .register-from-tab {
            padding: 4px;
            background: #f5f5f5;
            border-radius: 2px;
            display: flex;
            margin-bottom: 56px;
            > span {
              height: 40px;
              flex: 1;
              text-align: center;
              line-height: 40px;
              font-size: 16px;
              color: #8c8c8c;
              cursor: pointer;
              border-radius: 2px;
              transition: all 0.3s;
              &.active {
                background: #fff;
                color: #EA0B06;
              }
            }
          }
          .forget-from-info {
            > div {
              margin-bottom: 24px;
              &:last-child {
                margin-bottom: 0;
              }
            }
            > p {
              margin-top: 56px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              line-height: 12px;
              > span {
                color: #EA0B06;
                cursor: pointer;
              }
            }
          }
          .register-btn {
            width: 100%;
            font-size: 14px;
            margin-top: 40px;
          }
        }
      }
      .register-success {
        text-align: center;
        > img {
          display: block;
          margin: 0 auto 40px;
        }
        > p {
          line-height: 19px;
          font-size: 20px;
          margin-bottom: 16px;
        }
        > span {
          display: block;
          line-height: 13px;
          font-size: 14px;
          color: #8c8c8c;
          margin-bottom: 56px;
        }
        > div {
          display: flex;
          > span {
            flex: 1;
            text-align: center;
            height: 40px;
            background: #ffffff;
            border: 1px solid #EA0B06;
            border-radius: 2px;
            cursor: pointer;
            color: #EA0B06;
            line-height: 40px;
            font-size: 14px;
            &:first-child {
              margin-right: 16px;
            }
          }
        }
      }
    }
    .register-footer-protocol {
      margin-top: 40px;
      > div {
        display: flex;
        justify-content: center;
        > span {
          line-height: 13px;
          font-size: 12px;
          color: #bfbfbf;
          margin-right: 12px;
          padding-right: 12px;
          border-right: 1px solid #bfbfbf;
          cursor: pointer;
          &:last-child {
            margin: 0;
            border: 0;
          }
        }
      }
      > p {
        line-height: 13px;
        font-size: 12px;
        color: #bfbfbf;
        margin-top: 16px;
        text-align: center;
      }
    }
  }
}
.num-code {
  display: flex;
  justify-content: space-between;
  > div {
    cursor: pointer;
  }
}
</style>
<style>
.register-protocol {
  display: flex;
  font-size: 12px;
  color: #595959;
}
.register-protocol > span {
  line-height: 16px;
}
.register-protocol > span > span {
  color: #EA0B06;
  cursor: pointer;
}
</style>
