<template>
  <img v-if="lazy" :alt="alt" v-lazy="src" :key="src" @click="$emit('click')" />
  <img v-else :alt="alt" :src="src" :key="src" @click="$emit('click')" />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "VImg",
  props: {
    attachmentId: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    defaultImg: {
      type: String,
      default() {
        return require("@/assets/img/kctp.png");
      },
    },
    //是否进行懒加载，无缝滚动插件和VueLazyLoad结合使用时不能正确加载图片，则将该参数设置为false
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(["settings"]),
    src() {
      const { settings, attachmentId, defaultImg } = this;
      if (!attachmentId) {
        return defaultImg;
      }
      return `${settings.OBS_PREFIX}/${attachmentId}`;
    },
  },
};
</script>
